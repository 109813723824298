import { finder } from '@package/utilities/dom/finder'
import { on } from '@package/utilities/dom/event'

import { getTarget } from '../utils/target'

import { createDebugDimensions } from './dimenions'
import { createGridLines } from './grid-lines'
import { createBounds } from './bounds'

export const createCssEdit = () => {
  const dims = createDebugDimensions()
  const grid = createGridLines()
  const bounds = createBounds()
  // let selected

  const handleMove = (e: MouseEvent) => {
    let target = getTarget(e)
    // // if (selected) target = selected
    if (target) {
      grid.target(target)
      dims.target(target)
      return bounds.target(target, { classes: true })
    }
    dims.update({ top: 0, left: 0, width: 0, height: 0 })
    return bounds.target()
  }

  const handleClick = (cb: (x: string) => void) => (e: MouseEvent) => {
    const target = getTarget(e)
    if (!target) return
    // if ( selected) return (selected = undefined)
    // selected = target
    cb(finder(target))
  }

  return {
    subscribe: (cb: (x: string) => void) => {
      let cleanup: (() => void)[] = []
      const send = handleClick(cb)
      bounds.mount()
      dims.mount()
      grid.mount()
      cleanup.push(on('pointermove', handleMove), on('click', send))
      return () => {
        cleanup.forEach((fn) => fn())
        grid.unmount()
        bounds.unmount()
        dims.unmount()
      }
    },
  }
}

export const createCssPreview = () => {
  const node = document.createElement('style')
  node.className = 'poima-ignore'
  let mounted = false
  return {
    get mounted() {
      return mounted
    },
    mount: <T extends { appendChild<T extends Node>(parent: T): T }>(parent: T = document.body as any) => {
      parent.appendChild(node)
      mounted = true
    },
    unmount: () => {
      node.remove()
      mounted = false
    },
    update: (x: string) => {
      node.textContent = x
    },
  }
}
