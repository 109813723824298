import { treeSelector } from '@package/utilities/dom/selector'
import { on } from '@package/utilities/dom/event'

import { getTarget } from '../utils/target'
import { createBounds } from './bounds'

type Diff = { target: string; nodename: string; from: string; to: string }
const active_changes = new Map<HTMLElement, string>()
const cleanupContentDiffs = () =>
  active_changes.forEach((value, key) => {
    key.innerHTML = value
    key.contentEditable = 'false'
    active_changes.delete(key)
  })

export const createContentDiff = () => {
  const bounds = createBounds()
  let editing: HTMLElement | null = null
  let diffs: Record<string, Diff> = {}

  const handleKeyPress = (cb: (x: Record<string, Diff>) => void) => (e: KeyboardEvent) => {
    if (!editing) return
    const target = treeSelector(editing)
    const to = editing.innerHTML
    const from = active_changes.get(editing) || ''
    diffs[target] = { target, to, from, nodename: editing.nodeName }
    cb(diffs)
  }

  const handleMove = (e: MouseEvent) => {
    const target = getTarget(e)
    if (editing) return
    if (target) return bounds.target(target)
    return bounds.target()
  }

  const handleClick = (e: MouseEvent) => {
    const target = getTarget(e)
    if (!target) return
    if (!editing) {
      bounds.unmount()
      editing = target
      target.contentEditable = 'true'
      target.focus()
      if (!active_changes.has(editing)) active_changes.set(editing, editing.innerHTML)
    }
    if (editing === target) return
    editing.contentEditable = 'false'
    editing = null
    bounds.mount()
    bounds.target(target)
  }

  return {
    subscribe: (mount: Record<string, Diff>, cb: (x: Record<string, Diff>) => void) => {
      let cleanup: (() => void)[] = []
      cleanupContentDiffs()
      const keyPress = handleKeyPress(cb)
      editing = null
      diffs = mount
      bounds.mount()
      cleanup.push(on('pointermove', handleMove), on('click', handleClick), on('keypress', keyPress))

      Object.keys(mount).forEach((key) => {
        const diff = mount[key]
        const target = document.querySelector(diff.target)
        if (!target) return
        active_changes.set(target as HTMLElement, target.innerHTML)
        setTimeout(() => (target.innerHTML = diff.to), 0)
      })

      return () => {
        cleanupContentDiffs()
        bounds.unmount()
        cleanup.forEach((fn) => fn())
      }
    },
  }
}

export const previewContentDiff = (mount: Record<string, Diff>) => {
  const all = Object.keys(mount)
  const targets = all.map((key) => document.querySelector(mount[key].target))
  active_changes.forEach((value, key) => {
    if (targets.some((y) => y === key)) return
    key.innerHTML = value
    active_changes.delete(key)
  })
  Object.keys(mount).forEach((key) => {
    const diff = mount[key]
    const target = <HTMLElement | null>document.querySelector(diff.target)
    if (!target) return
    if (!active_changes.has(target)) active_changes.set(target, target.innerHTML)
    setTimeout(() => (target.innerHTML = diff.to), 0)
  })
}
