import { createLogo } from '@shared/components/ui/LogoSvg'

import { FrameState, FrameStore } from './store'
import { css } from '../../utils/dom'

export const createReactiveButton = (store: FrameStore) => {
  const node = document.createElement('button')
  node.innerHTML = createLogo(false, '', 'width: 1.2rem;')
  node.setAttribute(
    'style',
    // prettier-ignore
    css`--left: auto; --top: auto; --right: auto; --bottom: auto; --display: block; left: var(--left); top: var(--top); right: var(--right); bottom: var(--bottom); display: var(--display); z-index: 2147483646; position: fixed; color: blue;`,
  )
  document.body.appendChild(node)

  const toggle = () => store.setState((x) => ({ open: !x.open }))
  node.addEventListener('click', toggle)

  const offset = { x: `20px`, y: `20px` }

  type ButtonConfig = { display: 'block' | 'none'; left: string; top: string; right: string; bottom: string }
  const update = (updates: Partial<ButtonConfig>) => {
    for (let key in updates) {
      node.style.setProperty(`--${key}`, updates[key])
    }
  }

  const handleUpdate = (state: FrameState) => {
    let updates: Partial<ButtonConfig> = { top: 'auto', left: 'auto', right: 'auto', bottom: 'auto' }

    if (state.open) updates.display = 'none'
    else updates.display = 'block'
    if (state.mount === 'br') (updates.right = offset.x), (updates.bottom = offset.y)
    if (state.mount === 'bl') (updates.left = offset.x), (updates.bottom = offset.y)
    if (state.mount === 'tr') (updates.right = offset.x), (updates.top = offset.y)
    if (state.mount === 'tl') (updates.left = offset.x), (updates.top = offset.y)

    update(updates)
  }

  handleUpdate(store.getState())
  const unsub = store.subscribe((x) => handleUpdate(x))

  return () => {
    unsub()
    node.removeEventListener('click', toggle)
    node.remove()
  }
}
