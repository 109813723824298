import { createHtml } from '../../utils/dom'

import markup from './markup.html?raw'
import styles from './styles.css?raw'

export const createBounds = () => {
  const node = PoimaDebug.create()
  const target: PoimaDebug['target'] = (elem, options) => node.target(elem, options)

  return {
    mount: <T extends { appendChild<T extends Node>(parent: T): T }>(parent: T = document.body as any) => {
      target()
      parent.appendChild(node)
    },
    unmount: () => node.remove(),
    target,
  }
}

export class PoimaDebug extends HTMLElement {
  static elementName = 'poima-debug'
  classes: HTMLDivElement
  svg: SVGSVGElement
  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    const style = document.createElement('style')
    style.textContent = styles

    this.svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    const [svg, classes] = createHtml(markup.replace(/\n/gim, ''))

    if (!(svg instanceof SVGSVGElement)) throw new Error(`Expected svg element`)
    this.svg = svg

    if (!(classes instanceof HTMLDivElement)) throw new Error(`Expected div element`)
    this.classes = classes

    this.shadowRoot?.append(style, this.svg, this.classes)
  }
  static create() {
    return <PoimaDebug>document.createElement(PoimaDebug.elementName)
  }
  target(elem?: Element, options?: { classes?: boolean }) {
    let pos = { top: 0, left: 0, width: 0, height: 0 }
    let classes: string[] = []
    if (elem) {
      const box = elem.getBoundingClientRect()
      const body = document.documentElement.getBoundingClientRect()
      classes = options?.classes
        ? elem.className
            .split(' ')
            .map((x) => x.trim())
            .filter(Boolean)
        : []

      pos = {
        width: box.width,
        height: box.height,
        top: box.top - body.top,
        left: box.left - body.left,
      }
    }

    this.svg?.setAttribute('viewBox', `0 0 ${pos.width} ${pos.height}`)
    this.svg?.setAttribute('width', `${pos.width}`)
    this.svg?.setAttribute('height', `${pos.height}`)
    if (options?.classes) {
      this.classes.innerHTML = classes.map((x) => `<p>${x}</p>`).join('')
    }
    this.setAttribute('style', vars(pos))
  }
}

const vars = (obj: object) =>
  Object.keys(obj).reduce((a, b) => a + `;--${b}:${obj[b]}${typeof obj[b] === 'number' ? 'px' : ''}`, '')

customElements.define(PoimaDebug.elementName, PoimaDebug)
