export const outerBounds = (elem: Element) => {
  const computed = window.getComputedStyle(elem)
  const box = elem.getBoundingClientRect()

  let height = box.height
  let width = box.width
  let left = box.left
  let top = box.top

  // Outer
  const oh =
    height +
    parseInt(computed.marginTop, 10) +
    parseInt(computed.marginBottom, 10) +
    parseInt(computed.borderTopWidth, 10) +
    parseInt(computed.borderBottomWidth, 10)

  const ow =
    width +
    parseInt(computed.marginTop, 10) +
    parseInt(computed.marginBottom, 10) +
    parseInt(computed.borderTopWidth, 10) +
    parseInt(computed.borderBottomWidth, 10)

  const ol = left - parseInt(computed.marginLeft, 10) - parseInt(computed.borderLeftWidth, 10)
  const ot = top - parseInt(computed.marginTop, 10) - parseInt(computed.borderTopWidth, 10)

  const outer = { width: ow, height: oh, left: ol, top: ot }

  // Margin
  const margin = { width, height, left: left - ol, top: top - ot }

  // Padding
  const ph = height - parseInt(computed.paddingTop, 10) - parseInt(computed.paddingBottom, 10)
  const pw = width - parseInt(computed.paddingLeft, 10) - parseInt(computed.paddingRight, 10)
  const pl = margin.left + parseInt(computed.paddingLeft, 10)
  const pt = margin.top + parseInt(computed.paddingTop, 10)

  const padding = { width: pw, height: ph, left: pl, top: pt }

  return { outer, margin, padding }
}
