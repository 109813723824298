import styles from './frame.css'

const html = String.raw

export type PoimaUIVariables = {
  pointer: 'all' | 'none'
  right: string
  bottom: string
  top: string
  left: string
  width: string
  height: string
  display: 'none' | 'block'
  'resize-direction': 'ltr' | 'rtl'
}
export class PoimaUI extends HTMLElement {
  static elementName = 'poima-ui'
  frame: HTMLIFrameElement
  container: HTMLDivElement
  closeBtn: HTMLButtonElement
  dragHandler: HTMLDivElement
  constructor() {
    super()

    this.attachShadow({ mode: 'open' })

    this.container = document.createElement('div')

    this.frame = document.createElement('iframe')
    this.container.appendChild(this.frame)
    this.frame.setAttribute('sandbox', 'allow-scripts allow-popups allow-popups-to-escape-sandbox allow-same-origin')
    this.frame.setAttribute('fetchpriority', 'low')
    this.frame.setAttribute('loading', 'lazy')

    this.closeBtn = document.createElement('button')
    this.container.appendChild(this.closeBtn)
    this.closeBtn.id = 'close'
    // prettier-ignore
    this.closeBtn.innerHTML = html`<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="w-5 h-5 text-black/50 hover:text-black/100" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg>`

    this.dragHandler = document.createElement('div')
    this.dragHandler.id = 'drag-handler'
    this.container.appendChild(this.dragHandler)

    const style = document.createElement('style')
    style.textContent = styles

    this.shadowRoot?.append(style, this.container)
  }
  static create() {
    const elem = <PoimaUI>document.createElement(PoimaUI.elementName)
    return elem
  }
  update(settings: Partial<PoimaUIVariables>) {
    for (let key in settings) {
      this.style.setProperty(`--${key}`, settings[key])
    }
  }
}

customElements.define(PoimaUI.elementName, PoimaUI)
