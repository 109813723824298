import { css } from '../utils/dom'

export const createImagePrview = () => {
  let node: HTMLElement | undefined
  return {
    show: (src: string) => {
      console.log('show', src)
      const img = document.createElement('img')
      img.src = src
      img.setAttribute('style', `object-fit: contain;width: 100%;height: 100%;`)
      node = document.createElement('div')
      node.appendChild(img)
      node.style.zIndex = '2147483647'
      // prettier-ignore
      node.setAttribute('style', css`z-index:2147483647;position:fixed;top:0;left:0;width:100vw;height:100vh;background:rgba(0,0,0,.8);padding:20px;`)
      const remove = () => {
        node?.remove()
        node?.removeEventListener('click', remove)
      }
      node.addEventListener('click', remove)
      document.body.appendChild(node)
    },
    hide: () => node?.remove(),
  }
}
