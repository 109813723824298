import styles from './grid-lines.css?raw'

export const createGridLines = () => {
  const node = PoimaGridLines.create()
  const target: PoimaGridLines['target'] = (elem, options) => node.target(elem, options)

  return {
    mount: <T extends { appendChild<T extends Node>(parent: T): T }>(parent: T = document.body as any) => {
      parent.appendChild(node)
    },
    unmount: () => node.remove(),
    target,
  }
}

export class PoimaGridLines extends HTMLElement {
  static elementName = 'poima-grid-lines'
  svg: SVGSVGElement
  lines: [SVGLineElement, SVGLineElement, SVGLineElement, SVGLineElement]
  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this.svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    const l1 = document.createElementNS('http://www.w3.org/2000/svg', 'line')
    const [l2, l3, l4] = [
      <SVGLineElement>l1.cloneNode(),
      <SVGLineElement>l1.cloneNode(),
      <SVGLineElement>l1.cloneNode(),
    ]
    this.lines = [l1, l2, l3, l4]
    this.svg.append(...this.lines)

    const style = document.createElement('style')
    style.textContent = styles

    this.shadowRoot?.append(this.svg, style)
  }
  static create() {
    return <PoimaGridLines>document.createElement(PoimaGridLines.elementName)
  }
  target(elem: Element, options?: { pointer?: boolean }) {
    const box = elem.getBoundingClientRect()
    const body = document.documentElement.getBoundingClientRect()

    const pos = {
      top: box.top,
      left: box.left,
      width: box.width,
      height: box.height,
      'pointer-events': options?.pointer ? 'all' : 'none',
    }

    this.setAttribute('style', `--top: ${body.top * -1}px; --left: ${body.left}px`)

    const [l1, l2, l3, l4] = this.lines
    // Top
    l1.setAttribute('x1', `0`)
    l1.setAttribute('y1', `${pos.top}`)
    l1.setAttribute('x2', `${window.innerWidth}`)
    l1.setAttribute('y2', `${pos.top}`)

    // Top
    l2.setAttribute('x1', `0`)
    l2.setAttribute('y1', `${pos.top + box.height}`)
    l2.setAttribute('x2', `${window.innerWidth}`)
    l2.setAttribute('y2', `${pos.top + box.height}`)

    // Left
    l3.setAttribute('x1', `${pos.left}`)
    l3.setAttribute('y1', `0`)
    l3.setAttribute('x2', `${pos.left}`)
    l3.setAttribute('y2', `${body.height}`)

    // Right
    l4.setAttribute('x1', `${pos.left + box.width}`)
    l4.setAttribute('y1', `0`)
    l4.setAttribute('x2', `${pos.left + box.width}`)
    l4.setAttribute('y2', `${body.height}`)

    this.svg?.setAttribute('viewBox', `0 0 ${window.innerWidth} ${window.innerHeight}`)
  }
}
customElements.define(PoimaGridLines.elementName, PoimaGridLines)
