import { defaultConfig } from '../config'
import { createIframe } from '../setup/iframe'

const siteId = Array.from(document.querySelectorAll('script'))
  .find((x) => /inject/.test(x.src))
  ?.getAttribute('data-siteId')

if (window.onLoadPoima) window.onLoadPoima(createIframe)
else {
  const poima = createIframe({
    ...defaultConfig,
    ...({ siteId: siteId } as any),
  })
  poima.init()
}
