import { loginWithGoogle } from '@shared/sdk/auth/handle-login-plugin'
import { logout } from '@shared/sdk/auth/handle-logout-plugin'

import { getCreateTarget } from '@package/utilities/mm/createTarget'
import { Connection } from '@package/utilities/mm/types'

import { createContentDiff, previewContentDiff } from '../components/contentDiff'
import { createCssEdit, createCssPreview } from '../components/css'
import { createImagePrview } from '../components/imagePreview'
import { createHistoryListener } from '../components/location'
import { ConnectedApi } from '../../shared'

export const connectTargets = (inter: Connection<any>) => {
  const all = [
    t_location(inter),
    t_viewport(inter),
    t_content_watch(inter),
    t_content_preview(inter),
    t_css_watch(inter),
    t_css_preview(inter),
    t_goto(inter),
    t_login(inter),
    t_logout(inter),
    t_drag_start(inter),
    t_image_preview(inter),
  ]
  return () => all.forEach((cb) => cb())
}

// -----------------------------------------------------------------
// Create target listeners
// -----------------------------------------------------------------
const createTarget = getCreateTarget<ConnectedApi>()

// Viewport listener
const getViewport = () => ({ width: window.innerWidth, height: window.innerHeight })
const t_viewport = createTarget('parent', 'viewport', {
  call: getViewport,
  subscribe: (_a, cb) => {
    const handler = () => cb(getViewport())
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  },
})

// Location listener
const getLocation = () => ({
  origin: window.location.origin,
  pathname: window.location.pathname,
  href: window.location.href,
  host: window.location.host,
})
const subscribeHistory = createHistoryListener()
const t_location = createTarget('parent', 'location', {
  call: getLocation,
  subscribe: (_a, cb) => subscribeHistory(() => setTimeout(() => cb(getLocation()), 100)),
})

// Content diff
const diff = createContentDiff()
const t_content_watch = createTarget('parent', 'content_watch', {
  subscribe: (props, cb) => diff.subscribe(props.arg, cb),
})

const t_content_preview = createTarget('parent', 'content_preview', {
  call: (x) => previewContentDiff(x),
})

// Handle css editing
const css_watch_previewer = createCssPreview()
const css_edit = createCssEdit()
const t_css_watch = createTarget('parent', 'css_watch', {
  call: (x) => {
    if (!x) css_watch_previewer.unmount()
    if (x && !css_watch_previewer.mounted) css_watch_previewer.mount()
    if (x) css_watch_previewer.update(x)
    return x
  },
  subscribe: (props, cb) => css_edit.subscribe(cb),
})

// Handle css editing
const css_previewer = createCssPreview()
const t_css_preview = createTarget('parent', 'css_preview', {
  call: (x) => {
    if (!x) css_previewer.unmount()
    if (x && !css_previewer.mounted) css_previewer.mount()
    if (x) css_previewer.update(x)
    return x
  },
})

const t_drag_start = createTarget('parent', 'drag_start', {
  call: () => console.log('start dragging'),
})

// Handle css editing
const t_goto = createTarget('parent', 'goto', { call: (x) => (window.location.href = x) })
const t_login = createTarget('parent', 'login', { call: (x) => loginWithGoogle(x.endpoint, x.redirect_uri) })
const t_logout = createTarget('parent', 'logout', {
  call: (x) => Promise.resolve(logout(x.endpoint, x.redirect_uri)).then(() => null),
})

// Handle image preview
const previewer = createImagePrview()
const t_image_preview = createTarget('parent', 'image_preview', { call: (x) => previewer.show(x) })
