import { Connection } from '@package/utilities/mm/types'
import { asyncJoin } from '@package/utilities/mm/connect'

export const getConnection = (
  send: (message: any, targetOrigin: string, transfer?: Transferable[] | undefined) => void,
): Connection<any> =>
  asyncJoin({
    publish: (x) => send(x, '*'),
    subscribe: (cb) => {
      window.addEventListener('message', (x) => cb(x.data))
      return () => {
        window.removeEventListener('message', (x) => cb(x.data))
      }
    },
  })
