import { pubsub } from '@package/utilities/mm/pubsub'

export const createHistoryListener = () => {
  type Events =
    | { type: 'push'; data: any; unused: string; url?: string | URL | null | undefined }
    | { type: 'replace'; data: any; unused: string; url?: string | URL | null | undefined }
  const channels = pubsub<{ location: Events }>()
  window.history.pushState = new Proxy(window.history.pushState, {
    apply: (target, thisArg, argArray) => {
      channels.publish('location', { type: 'push', data: argArray[0], unused: argArray[1], url: argArray[2] })
      return target.apply(thisArg, argArray as any)
    },
  })
  window.history.replaceState = new Proxy(window.history.replaceState, {
    apply: (target, thisArg, argArray) => {
      channels.publish('location', { type: 'replace', data: argArray[0], unused: argArray[1], url: argArray[2] })
      return target.apply(thisArg, argArray as any)
    },
  })
  return (cb: (x: Events) => void) => channels.subscribe('location', cb)
}
