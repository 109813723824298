import { persist } from '@package/utilities/zustand/persist'
import { on } from '@package/utilities/dom/event'

import { createFrameStore, FrameState, FrameStore, intialState } from './store'
import { PoimaUI, PoimaUIVariables } from '../../components/iframe'

import { getConnection } from '../../utils/connection'
import { createReactiveButton } from './button'
import type { PluginConfig } from '../../config'
import { connectTargets } from '../targets'

export const createIframe = (config: PluginConfig) => {
  let listeners = new Set<() => void>()
  let store: FrameStore | undefined

  const init = () => {
    const ui = PoimaUI.create()
    ui.frame.src = `${config.url}/${config.siteId}`
    listeners.add(() => ui.remove())
    const updateFrame = getUpdateFrame(ui) // Function to update frame based on state

    // Create the store for frame state
    const inter = getConnection((...args) => ui.frame.contentWindow?.postMessage(...args))
    const _store = createFrameStore()
    store = _store
    persist(_store, { key: `poima-ui:${config.siteId}`, timeout: 1_000 * 60 * 60 * 12 })

    // Update the frame to current state
    updateFrame(_store.getState())
    listeners.add(_store.subscribe(() => updateFrame(_store.getState())))

    // Connection listeners to the current context
    listeners.add(connectTargets(inter))

    // Add listener for frame close button
    listeners.add(on(ui.closeBtn, 'click', () => _store.setState((x) => ({ open: false }))))

    // Add frame drag listeners
    listeners.add(handleDragFrame(ui, _store))

    // Listen to container resize
    const updateSize = debounce(1000, () => {
      const box = ui.container.getBoundingClientRect()
      _store.setState({ width: box.width, height: box.height })
    })
    const obs = new ResizeObserver(updateSize)
    obs.observe(ui.container)
    listeners.add(() => obs.disconnect())

    // Add frame drag listeners
    if (config.button) listeners.add(createReactiveButton(store))
  }

  const destroy = () => {
    listeners.forEach((fn) => fn())
    listeners = new Set()
    store = undefined
  }

  return { init, destroy, update: (x: Partial<FrameState>) => store?.setState(x) }
}

// -----------------------------------------------------------------
// Update frame state
// -----------------------------------------------------------------
const getUpdateFrame = (ui: PoimaUI) => {
  let mounted = false
  return (state: FrameState) => {
    const updates: Partial<PoimaUIVariables> = {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      width: `${state.width ? Math.max(100, state.width) : intialState.width}px`,
      height: `${state.height ? Math.max(100, state.height) : intialState.height}px`,
    }
    const [x, y] = [`${state.offset.x}px`, `${state.offset.y}px`]

    if (state.mount === 'br') (updates.right = x), (updates.bottom = y)
    if (state.mount === 'bl') (updates.left = x), (updates.bottom = y)
    if (state.mount === 'tr') (updates.right = x), (updates.top = y)
    if (state.mount === 'tl') (updates.left = x), (updates.top = y)

    if (state.open) {
      updates.display = 'block'
      if (!mounted) {
        mounted = true
        document.body.appendChild(ui)
      }
    } else updates.display = 'none'

    if (/r/.test(state.mount)) updates['resize-direction'] = 'rtl'
    else updates['resize-direction'] = 'ltr'
    ui.update(updates)
  }
}

// -----------------------------------------------------------------
// Handle dragging the iframe box
// -----------------------------------------------------------------
const handleDragFrame = (ui: PoimaUI, store: FrameStore) => {
  const state = { down: false, mouse: { x: 0, y: 0 }, offset: store.getState().offset }
  const handleMove = (x: number, y: number) => {
    if (!state.down) return onUp()
    const current = store.getState()
    state.offset = {
      x: /r/.test(current.mount) ? current.offset.x + (state.mouse.x - x) : current.offset.x - (state.mouse.x - x),
      y: /b/.test(current.mount) ? current.offset.y + (state.mouse.y - y) : current.offset.y - (state.mouse.y - y),
    }
    if (current.mount === 'br') ui.update({ right: `${state.offset.x}px`, bottom: `${state.offset.y}px` })
    if (current.mount === 'bl') ui.update({ left: `${state.offset.x}px`, bottom: `${state.offset.y}px` })
    if (current.mount === 'tr') ui.update({ right: `${state.offset.x}px`, top: `${state.offset.y}px` })
    if (current.mount === 'tl') ui.update({ left: `${state.offset.x}px`, top: `${state.offset.y}px` })
  }

  const onMove = (e: PointerEvent) => handleMove(e.clientX, e.clientY)
  const onTMove = (e: TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    handleMove(e.touches[0].clientX, e.touches[0].clientY)
  }

  const handleDown = (x: number, y: number) => {
    state.mouse = { x, y }
    state.offset = store.getState().offset
    state.down = true
    ui.update({ pointer: 'none' })

    window.addEventListener('pointerup', onUp)
    window.addEventListener('pointermove', onMove)
    window.addEventListener('touchmove', onTMove, false)
    window.addEventListener('touchend', onUp, false)
  }

  const onDown = (e: PointerEvent) => handleDown(e.clientX, e.clientY)
  const onTDown = (e: TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    handleDown(e.touches[0].clientX, e.touches[0].clientY)
  }

  const onUp = () => {
    state.down = false
    store.setState({ offset: state.offset })
    ui.update({ pointer: 'all' })
    window.removeEventListener('pointerup', onUp)
    window.removeEventListener('pointermove', onMove)
    window.removeEventListener('touchmove', onTMove, false)
    window.removeEventListener('touchend', onUp, false)
  }

  const onReset = () => store.setState({ offset: intialState.offset })

  ui.dragHandler.addEventListener('dblclick', onReset)
  ui.dragHandler.addEventListener('pointerdown', onDown)
  ui.dragHandler.addEventListener('touchstart', onTDown, { passive: true })
  return () => {
    window.removeEventListener('pointerup', onUp)
    window.removeEventListener('pointermove', onMove)
    window.removeEventListener('touchmove', onTMove, false)
    window.removeEventListener('touchend', onUp, false)
    ui.dragHandler.removeEventListener('pointerdown', onDown)
    ui.dragHandler.removeEventListener('dblclick', onReset)
    ui.dragHandler.removeEventListener('touchstart', onTDown, false)
  }
}

const debounce = <A extends any[]>(d: number, fn: (...args: A) => any) => {
  let timeout = setTimeout(() => {}, 0)
  return (...args: A) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => fn(...args), d)
  }
}
