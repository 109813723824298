import createStore, { StoreApi } from 'zustand/vanilla'

export interface FrameState {
  mount: 'tl' | 'tr' | 'bl' | 'br'
  offset: { x: number; y: number }
  width: number
  height: number
  open: boolean
  button: boolean
}
const initialFrame: FrameState = {
  open: false,
  mount: 'br',
  offset: { x: 20, y: 20 },
  width: 352,
  height: 480,
  button: true,
}

export const intialState = initialFrame

export const createFrameStore = (state: FrameState = intialState) => createStore<FrameState>(() => state)

export type FrameStore = StoreApi<FrameState>
