import { PoimaDebug } from '../components/bounds'
import { PoimaUI } from '../components/iframe'

export const getTarget = (e: MouseEvent) => {
  const custom = [PoimaUI.elementName, PoimaDebug.elementName]
  const layer = 0
  const all = document.elementsFromPoint(e.clientX, e.clientY)
  const ignore = all.some((y) => y.nodeName === 'POIMA-UI')
  if (ignore) return null
  if (all.some(isIgnored)) return null
  const target =
    all.filter((x) => !/poima-ignore/.test(x.className) && !custom.some((y) => y.toUpperCase() === x.nodeName))?.[
      layer
    ] || null
  if (!(target instanceof HTMLElement)) return null
  return target
}

const isIgnored = (elem?: Node | null): boolean =>
  !elem ? false : /poima-ignore/.test((elem as any).className || '') ? true : isIgnored(elem.parentNode)
