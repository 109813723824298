import type { FrameState } from './setup/iframe/store'

declare global {
  interface Window {
    onLoadPoima: (
      create: (config: PluginConfig) => { init: () => void; destroy: () => void; update: (x: FrameState) => void },
    ) => void
  }
}

export interface PluginConfig {
  position: 'bl' | 'br' | 'tl' | 'tr' // default br
  button: boolean
  siteId: string
  url: string
}

const plugin = process.env.IS_LOCAL ? 'http://localhost:3001' : (process.env.PLUGIN_UI_URL as string)
const env = { plugin }

const siteId = Array.from(document.querySelectorAll('script'))
  .find((x) => /inject/.test(x.src))
  ?.getAttribute('data-siteId') as string

export const defaultConfig = (<T extends PluginConfig>(x: T) => x)({
  position: 'br', // default br
  button: true,
  siteId,
  url: env.plugin,
} as const)
