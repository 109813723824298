import { outerBounds } from '../../utils/element'
import { createHtml } from '../../utils/dom'

import markup from './markup.html?raw'
import styles from './styles.css?raw'

export const createDebugDimensions = () => {
  const node = PoimaDebugDimensions.create()
  const target: PoimaDebugDimensions['target'] = (elem, options) => node.target(elem, options)
  const update: PoimaDebugDimensions['update'] = (options) => node.update(options)
  return {
    mount: <T extends { appendChild<T extends Node>(parent: T): T }>(parent: T = document.body as any) => {
      node.update({ top: 0, left: 0, width: 0, height: 0 })
      parent.appendChild(node)
    },
    unmount: () => node.remove(),
    update,
    target,
  }
}

export class PoimaDebugDimensions extends HTMLElement {
  static elementName = 'poima-debug-dimensions'

  svg: SVGSVGElement
  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    const style = document.createElement('style')
    style.textContent = styles
    const [svg] = createHtml(markup.replace(/\n/gim, ''))

    if (!(svg instanceof SVGSVGElement)) throw new Error(`Expected svg element`)
    this.svg = svg

    this.shadowRoot?.append(style, this.svg)
  }
  static create() {
    return <PoimaDebugDimensions>document.createElement(PoimaDebugDimensions.elementName)
  }
  target(elem: Element, options?: { classes?: boolean; pointer?: boolean }) {
    const b = outerBounds(elem)
    const body = document.documentElement.getBoundingClientRect()
    const pos = {
      'o-top': b.outer.top - body.top,
      'o-left': b.outer.left - body.left,
      'o-width': b.outer.width,
      'o-height': b.outer.height,
      'm-top': b.margin.top,
      'm-left': b.margin.left,
      'm-width': b.margin.width,
      'm-height': b.margin.height,
      'p-top': b.padding.top,
      'p-left': b.padding.left,
      'p-width': b.padding.width,
      'p-height': b.padding.height,
      'pointer-events': options?.pointer ? 'all' : 'none',
    }

    this.svg?.setAttribute('viewBox', `0 0 ${pos['o-width']} ${pos['o-height']}`)
    this.setAttribute('style', vars(pos))
  }
  update(pos: { top: number; left: number; width: number; height: number }, options?: { classes?: string[] }) {
    this.svg?.setAttribute('viewBox', `0 0 ${pos.width} ${pos.height}`)

    this.setAttribute('style', vars(pos))
  }
}

const vars = (obj: object) =>
  Object.keys(obj).reduce((a, b) => a + `;--${b}:${obj[b]}${typeof obj[b] === 'number' ? 'px' : ''}`, '')

customElements.define(PoimaDebugDimensions.elementName, PoimaDebugDimensions)
